import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const AevWorkCategory = () => {
  return (
    <section className="work-category-in-details explainer-video-production">
      <Container>
        <Row className="portfolio-content-home">
          <Col md={12}>
            <h2>Our Explainer Video Process & Deliverables</h2>
            <p>
              We break down complicated, boring and brand-new concepts, extract
              the juice and convert in to simple, short and sharable explainer
              videos that attract, educate and convert your target audience.
            </p>
            <Col md={9}>
              <div className="row">
                <Col md={6} sm={6}>
                  <h3>Explore</h3>
                  <ul className="item">
                    <li>Audience Research</li>
                    <li>Competitive Analysis</li>
                    <li>Business Objectives</li>
                    <li>Brand Perception</li>
                  </ul>
                </Col>
                <Col md={6} sm={6}>
                  <h3>Storyline</h3>
                  <ul className="item">
                    <li>Video Concept</li>
                    <li>Script Writing</li>
                    <li>Storyboard</li>
                  </ul>
                </Col>
                <Col md={6} sm={6}>
                  <h3>Production</h3>
                  <ul className="item item-mb-desktop-none">
                    <li>Character Illustration</li>
                    <li>Background Design</li>
                    <li>Animation &amp; Visual Effects</li>
                    <li>Voice Over &amp; Music</li>
                  </ul>
                </Col>
                <Col md={6} sm={6}>
                  <h3>Delivery</h3>
                  <ul className="item item-mb-desktop-none item-bottom-m">
                    <li>Quality Check</li>
                    <li>Feedback &amp; Iterations</li>
                    <li>HD Files In All Formats</li>
                  </ul>
                </Col>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AevWorkCategory
