import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import ButtonIcon from "../icon/buttonIcon"
import { Col, Container, Row } from "react-bootstrap"

const AevMoreAbout = () => {
  return (
    <section className="more-about-ev">
      <div className="content-more-ev">
        <Container>
          <Row>
            <Col md={12}>
              <Col md={6}>
                <div className="row wtkm-ev-title">
                  <h2> Want To Know More About Explainer Videos?</h2>
                  <p>
                    We’ve made an explainer video guide with the help of top
                    industry experts like Neil Patel, Jeff Bullas, Wistia and
                    Vidyard. Find out how it works, its benefits and how to
                    create a great explainer video for you.
                  </p>
                  <Link
                    to="/explainer-video/"
                    class="btn wowaction-btn drift-chat-popup"
                  >
                    <ButtonIcon />
                    <span>Read our Explainer Video Guide</span>
                  </Link>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="experts">
        <Container>
          <Row className="text-center">
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/neil-patel.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Neil Patel</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/bruce-clay.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Bruce Clay</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/shayla-price.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Shayla Price</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/jeff-bullas.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Jeff Bullas</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/aldrich-obach.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Aldrich Obach</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/margot-mazur.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Margot Mazur</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/sam-hurley.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Sam Hurley</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/moosa-hemani.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Moosa Hemani</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/cyrus-shepard.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Cyrus Shepard</p>
              </div>
            </Col>                                                                                                            
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/joe-elliott.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Joe Elliott</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/lou-bortone.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Lou Bortone</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/mordecai-holtz.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Mordecai Holtz</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/cameron-seher.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Cameron Seher</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/kimbe-macmaster.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Kimbe MacMaster</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/tim-ryan.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Tim Ryan</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/sujan-patel.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Sujan Patel</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/johnathan-dane.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Johnathan Dane</p>
              </div>
            </Col>
            <Col lg={1} md={2} xs={2}>
              <div className="expert">
                <StaticImage
                  src="../../images/animated-explainer-video/shanelle-mullin.jpeg"
                  alt="Neil Patel"
                  width={75}
                  height={75}
                  className="rounded-circle"
                  placeholder="blurred"
                />
                <p className="expert-name">Shanelle Mullin</p>
              </div>
            </Col>                                                                                                            
          </Row>          
        </Container>
      </div>
    </section>
  )
}

export default AevMoreAbout
