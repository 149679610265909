import React from "react"
import { Link } from "gatsby"
import ButtonIcon from "../icon/buttonIcon"
import AevPortfolio from "./aevPortfolio"
import WbvPortfolio from "./wbvPortfolio"
import LavPortfolio from "./lavPortfolio"

import { Container, Row, Col } from "react-bootstrap"
import "../../sass/global/work-tab.scss"

const AevWorks = () => {
 
  return (
    <section class="works portfolio-works">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">
              Our latest and greatest explainer videos
            </h2>
            <p className="sub-desc">
              You can use our digital storytelling expertise to explain your
              ideas, product or service to the world. Watch some of our
              successful explainer video production projects.
            </p>
          </Col>
          <Col md={12}>
            <div className="our-work-tab mt-5">
              <ul className="d-flex flex-column flex-md-row align-items-center justify-content-center mb-0 pl-0">
                <li className="pr-md-3 pr-0 mb-md-0 mb-4">
                  <button className="tab-btn active" id="aev" data-id="#animationev">Animated Explainer Videos</button>
                </li>
                <li className="px-md-3 px-0  mb-md-0 mb-4">
                  <button className="tab-btn" id="wv" data-id="#whiteboardev">Whiteboard Video</button>
                </li>
                <li className="pl-md-3 px-0">
                  <button className="tab-btn" id="lvp" data-id="#liveactionev">Live Video Production</button>
                </li>                                
              </ul>
            </div>
          </Col>
          <div className="wmtab-content active" id="animationev">            
              <AevPortfolio />
                <Col md={12} className="mt-5 pt-5 d-flex justify-content-center">
                  <Link
                      to="/works/videos/"
                      class="btn wowaction-btn"
                    >
                      <ButtonIcon />
                      <span>See more projects</span>
                    </Link>            
                </Col>               
          </div>
          <div className="wmtab-content" id="whiteboardev">            
            <WbvPortfolio />
            <Col md={12} className="mt-5 pt-5 d-flex justify-content-center">
              <Link
                  to="/whiteboard-video-production/"
                  class="btn wowaction-btn"
                >
                  <ButtonIcon />
                  <span>Know more about our Whiteboard Video Service</span>
                </Link>            
            </Col>             
          </div>
          <div className="wmtab-content" id="liveactionev">            
            <LavPortfolio />
            <Col md={12} className="mt-5 pt-5 d-flex justify-content-center">
              <Link
                  to="/live-video-production/"
                  class="btn wowaction-btn"
                >
                  <ButtonIcon />
                  <span>Know more about our Live Video Production Service</span>
                </Link>            
            </Col>
          </div>                    

        </Row>
      </Container>
    </section>
  )
}

export default AevWorks
