import React from "react"
import ButtonIcon from "../icon/buttonIcon"
import { Container, Row, Col } from "react-bootstrap"

const AevShowReel = () => {
  return (
    <section className="show-reel">
      <Container>
        <Row className="align-items-center">
          <Col lg={6} className="mb-lg-0 mb-5">
            <div className="sr-content">
              <h2>
                Watch our Explainer Video showreel and take a glimpse through
                some of our best works
              </h2>
              <a
                href="https://www.youtube.com/watch?v=XJ134SskYBs"
                class="btn wowaction-btn revert"
                target="_blank"
                rel="noreferrer"
              >
                <ButtonIcon />
                <span>Watch Show Reel</span>
              </a>
            </div>
          </Col>
          <Col lg={6}>
            <div className="gatsby-resp-iframe-wrapper">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/XJ134SskYBs?controls=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AevShowReel
