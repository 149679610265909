import React from "react";

function TrustIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="100"
      height="100"      
      enableBackground="new 0 0 150 150"
      version="1.1"
      viewBox="0 0 150 150"
      xmlSpace="preserve"
    >
      <circle cx="75" cy="75" r="75" fill="#FCF112" className="st0"></circle>
      <path
        d="M74.9 322.8h-.2c-.4.1-.7.2-.9.4-.2.1-3.4 2.5-8.4 5-5.1 2.6-11.8 5.1-17.6 5.1H46v26.1c0 13.3 7.3 22.5 14.4 28.2 7.1 5.7 14.2 8 14.2 8l.6.2.6-.2s7.1-2.4 14.2-8c7.1-5.7 14.4-14.8 14.4-28.2v-26.1h-1.8c-5.9 0-12.5-2.5-17.6-5.1-5-2.5-8.4-4.9-8.5-5-.3-.2-.6-.4-1-.4H74.9zM75 341c10.1 0 18.2 8.2 18.2 18.2 0 10.1-8.2 18.2-18.2 18.2-10.1 0-18.2-8.2-18.2-18.2S64.9 341 75 341zm10 9.1c-.7 0-1.4.3-1.9.8l-10.8 10.8-5.4-5.4a2.81 2.81 0 00-3.9-.1 2.81 2.81 0 00-.1 3.9l.1.1 7.3 7.3c1.1 1.1 2.8 1.1 3.9 0L87 354.8c1.1-1.1 1.1-2.8 0-3.9-.5-.5-1.3-.8-2-.8z"
        transform="translate(0 -284.3)"
      ></path>
    </svg>
  );
}

export default TrustIcon;
