import React from "react"
import ConveyMessageIcon from "../icon/conveyMessageIcon"
import DriveMoreIcon from "../icon/driveMoreIcon"
import ImproveIcon from "../icon/improveIcon"
import IncreaseIcon from "../icon/increaseIcon"
import SocialShareIcon from "../icon/socialShareIcon"
import TrustIcon from "../icon/trustIcon"

import { Container, Row, Col } from "react-bootstrap"
import '../../sass/global/why-you-need.scss'

const AevWhyYouNeed = () => {
  return (
    <section className="why-you-need">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">
              Why you need an Explainer video that works?
            </h2>
            <p className="sub-desc">
              We are a team of concept artists, writers, illustrators,
              designers, animators, marketers and engineers specialised in
              converting ideas and messages in to beautiful explainer videos
              that works. This is how it helps you:
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <ConveyMessageIcon />
              <h3 class="col-md-7">Convey Your Message</h3>
              <p>
                Explain your idea in a simple and interesting way so that you
                won’t turn your audience off.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <ImproveIcon />
              <h3 class="col-md-7">Improves Your SEO Ranking</h3>
              <p>
                Get higher search engine rankings by using relevant video
                content on your website.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <DriveMoreIcon />
              <h3 class="col-md-7">Drives More Traffic</h3>
              <p>
                Get more highly targeted audience to your website who could be
                your potential leads.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <TrustIcon />
              <h3 class="col-md-7">Build Trust To Your Brand</h3>
              <p>
                Use the power of visual cues to connect with your audience and
                build a bond with them.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <IncreaseIcon />
              <h3 class="col-md-7">Increase Conversion</h3>
              <p>
                A great video can help you to convert more and more leads into
                your actual customers.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <SocialShareIcon />
              <h3 class="col-md-7">More Social Media Shares</h3>
              <p>
                Videos get more reach and shares in social media, helping you
                promote your business.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AevWhyYouNeed
