import React from "react"
import MainLogo from "../global/mainLogo"
import ButtonIcon from "../icon/buttonIcon"
import { Col, Container, Row } from "react-bootstrap"

import "../../sass/global/main.scss"

const AevMain = () => {
  return (
    <div className="main">
      <Container>
        <Row>
          <Col md={12} lg={7} xl={6}>
            <div className="intro">
              <p className="service-label">EXPLAINER VIDEOS</p>
              <h1 class="title">Complexity Simplified: Your Idea, Our Canvas</h1>
              <p className="description">
                Simplifying complex ideas is our superpower. We transform your ideas into clear, engaging animations. Our explainer videos make your audience say, "Now I get it!". Your message, simplified and memorable.
              </p>
              <a href="/animated-explainer-video/#CQL" class="btn wowaction-btn drift-chat-popup">
                <ButtonIcon />
                <span>Let's Talk. Amplify Your Message with Video</span>
              </a>
            </div>
          </Col>
          <MainLogo />
        </Row>
      </Container>
    </div>
  )
}

export default AevMain
