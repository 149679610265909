import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import Slider from "react-slick"
import { PrevArrow, NextArrow } from "../global/customArrows";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../sass/global/testimonials.scss"
import "../../sass/global/slider.scss"

const AevTestimonials = () => {
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false
      }
    },
    {
      breakpoint: 787,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    }
  ]

  }
  return (
    <section className="testimonials animated-ev" style={{ background: "#f6f6f6" }}>
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">Clients turned brand ambassadors</h2>
            <p className="sub-desc">
              Our team take great pride in working with people and organisations
              exploring innovative ways to delight their audience. We are
              humbled and happy to see our efforts helping them achieve their
              vision.
            </p>
          </Col>
          <Col md={12}>
            <Slider {...settings}>
                <div class="testimonial">
                  <StaticImage
                    src="../../images/testimonials/ave-shije.jpeg"
                    alt=""
                    width={104}
                    height={104}
                    placeholder="blurred"
                  />
                  <div className="testimonial-content">
                    <p className="text">
                      “Wowmakers is a great company to work with. They're great
                      communicators–both with their clients and through
                      storytelling and animation to communicate a brand
                      message.”
                    </p>
                    <p className="c-name">
                      <span>Ave Shije</span>
                    </p>
                    <p className="c-designtion">Zenbox Marketing</p>
                  </div>
                </div>
                <div class="testimonial">
                  <StaticImage
                    src="../../images/testimonials/Muhammed-Riyas.jpeg"
                    alt=""
                    width={104}
                    height={104}
                    placeholder="blurred"
                  />
                  <div className="testimonial-content">
                    <p className="text">
                      “With a comprehensive initial project back brief,
                      WowMakers immediately demonstrated a solid grasp on the
                      video’s intent and target audience. ”
                    </p>
                    <p className="c-name">
                      <span>Muhammed Riyas</span>
                    </p>
                    <p className="c-designtion">
                      Senior Digital Marketing Manager, ST-YL
                    </p>
                  </div>
                </div>
                <div class="testimonial">
                  <StaticImage
                    src="../../images/testimonials/Surya.jpeg"
                    alt=""
                    width={104}
                    height={104}
                    placeholder="blurred"
                  />
                  <div className="testimonial-content">
                    <p className="text">
                      “WowMakers follows an efficient management process, and
                      they can leverage their innovative vision to deliver
                      videos that serve as an effective marketing asset.”
                    </p>
                    <p className="c-name">
                      <span>Surya Saraiya</span>
                    </p>
                    <p className="c-designtion">Founder, Cricpod</p>
                  </div>
                </div>
                <div class="testimonial">
                  <StaticImage
                    src="../../images/testimonials/Jeffin.jpeg"
                    alt=""
                    width={104}
                    height={104}
                    placeholder="blurred"
                  />
                  <div className="testimonial-content">
                    <p className="text">
                      “WowMakers’s creativity and attention to detail enabled
                      them to deliver videos that supported the project’s core
                      vision. They were a communicative and timely partner.”
                    </p>
                    <p className="c-name">
                      <span>Jeffin Idiculla</span>
                    </p>
                    <p className="c-designtion">
                      IT Specialist, Manage My Education
                    </p>
                  </div>
                </div>
                <div class="testimonial">
                  <StaticImage
                    src="../../images/testimonials/Bhagavan.jpeg"
                    alt=""
                    width={104}
                    height={104}
                    placeholder="blurred"
                  />
                  <div className="testimonial-content">
                    <p className="text">
                      “As a startup in the technology space, it was very
                      important to have a powerful and effective video to get
                      our messaging across. It was a great experience working
                      with WowMakers team.”
                    </p>
                    <p className="c-name">
                      <span>Bhagvan Chougule</span>
                    </p>
                    <p className="c-designtion">Co-Founder & CTO, Treeni</p>
                  </div>
                </div>              
                <div class="testimonial">
                  <StaticImage
                    src="../../images/testimonials/Karthik.jpeg"
                    alt=""
                    width={104}
                    height={104}
                    placeholder="blurred"
                  />
                  <div className="testimonial-content">
                    <p className="text">
                      “WowMakers makes some of the most powerful and beautiful
                      videos I've ever seen. They helped us distill our core
                      message quickly and painlessly, which was worth every
                      penny.”
                    </p>
                    <p className="c-name">
                      <span>Karthik Naralasetty</span>
                    </p>
                    <p className="c-designtion">Founder, Socialblood</p>
                  </div>
                </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AevTestimonials