import React from "react"
import ButtonIcon from "../../icon/buttonIcon"
import { Col, Container, Row } from "react-bootstrap"

const WhiteboardAnimationVideos = () => {
  return (
    <div className="video-type whiteboard-videos">
      <Container>
        <Row className="align-items-center">
          <Col md={6} lg={5}>
            <div className="type-content">
              <h3>Whiteboard Animation Videos</h3>
              <p>
                Whiteboard videos are geat for explaining complex problems or
                concepts in a simple and engaging format.
              </p>
              <p>
                The concepts are explained by narrating and drawing pictures on
                a whiteboard. These videos work best for thought leadership,
                employee training, safety etc eventhough they are also used for
                marketing, brand building and concept explanation.
              </p>
              <p class="last-child">
                Whiteboad videos comes in handy when the topic you have to
                explain is lengthy and complex.
              </p>
              <a
                href="/animated-explainer-video/#CQL"
                class="btn wowaction-btn drift-chat-popup"
              >
                <ButtonIcon />
                <span>Have a project in mind? Chat with us</span>
              </a>
            </div>
          </Col>
          <Col md={5} lg={6} className="offset-lg-1 offset-md-1">
            <div class="type-video two-d">
              <iframe
                width="100%"
                height="350"
                src="https://www.youtube.com/embed/lOP1gNLG58A"
                frameborder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="YouTube video player"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhiteboardAnimationVideos
