import React from "react";

function DriveMoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="100"
      height="100"      
      enableBackground="new 0 0 150 150"
      version="1.1"
      viewBox="0 0 150 150"
      xmlSpace="preserve"
    >
      <circle cx="75" cy="75" r="75" fill="#FCF112" className="st0"></circle>
      <path d="M76 34c-22.6 0-41 18.4-41 41s18.4 41 41 41 41-18.4 41-41-18.4-41-41-41zm2.5 77v-6.5h-4.9v6.5c-18-1.2-32.4-15.6-33.6-33.5h6.5v-4.9H40c1.2-18 15.6-32.4 33.5-33.6v6.5h4.9V39c17.9 1.2 32.3 15.6 33.5 33.5h-6.5v4.9h6.5c-1.1 18-15.5 32.4-33.4 33.6z"></path>
      <path d="M58.8 82.8c2.5-1.7 5.4-2.7 8.1-3.6.4-.1.8-.3 1.2-.4l.1-.1c.6-.2 1.1-.4 1.6-.6 0 0 .1-.1.1-.3-2.2-3.3-3.8-8.3-3.8-12.7 0-3.7 1.2-6.4 3.3-8.1-.9-.5-2.1-.7-3.3-.7-3.3 0-5.9 1.3-5.9 6 0 3.1 1.2 6.9 2.9 9.1 0 .2 0 .4-.1.5-.1.5-.3 1-.7 1.3-.5.3-1.1.5-1.6.7-2.3.8-4.6 1.4-6.6 2.8-1 .7-1.7 2-2.1 3.2-.4 1.2-.6 2.4-.5 4h6.2c.3-.7.7-.8 1.1-1.1zM97.9 76.8c-2-1.3-4.3-2-6.6-2.8-.5-.2-1.1-.4-1.6-.7-.4-.3-.6-.8-.7-1.3 0-.3-.1-.5-.1-.8 1.6-2.2 2.7-5.9 2.7-8.9 0-4.7-2.6-6-5.9-6-1.2 0-2.4.2-3.3.7 2.2 1.7 3.3 4.4 3.3 8 0 4.2-1.4 9.1-3.6 12.4v.1c.1.3.1.4.1.5.5.3 1 .5 1.7.7l.9.3c2.8.9 5.7 2.1 8.3 3.8.4.3.8.3 1.2 1.1h6.2c0-1.6-.1-2.8-.5-4-.3-1.1-1-2.4-2.1-3.1z"></path>
      <path d="M92.1 84.4c-2.7-1.8-5.8-2.7-8.8-3.7-.7-.3-1.5-.5-2.1-.9-.6-.4-.8-1.1-1-1.7-.1-.3-.1-.7-.1-1 2.2-3 3.6-7.9 3.6-11.9 0-6.2-3.5-8-7.9-8-4.3 0-7.9 1.7-7.9 8 0 4.1 1.6 9.2 3.9 12.2 0 .2 0 .5-.1.7-.1.6-.4 1.3-1 1.7-.7.4-1.4.6-2.1.9-3 1-6.1 1.9-8.8 3.7-1.4.9-2.3 2.7-2.9 4.2-.5 1.6-.8 3.7-.7 5.3h39.5c0-1.6-.2-3.7-.7-5.3-.6-1.6-1.5-3.3-2.9-4.2z"></path>
    </svg>
  );
}

export default DriveMoreIcon;