import React from "react";

function ImproveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="100"
      height="100"      
      enableBackground="new 0 0 150 150"
      version="1.1"
      viewBox="0 0 150 150"
      xmlSpace="preserve"
    >
      <circle cx="75" cy="75" r="75" fill="#FCF112" className="st0"></circle>
      <path d="M53.1 76.4c-1.4 0-2.6 1.2-2.6 2.6v14.2h13.7V79c0-1.4-1.2-2.6-2.6-2.6h-8.5zM73.2 66.2c-1.4 0-2.6 1.2-2.6 2.6v24.4h13.7V68.8c0-1.4-1.2-2.6-2.6-2.6h-8.5zM90.6 58.5v34.7h13.7V58.5c0-1.4-1.2-2.6-2.6-2.6h-8.6c-1.4.1-2.5 1.2-2.5 2.6zM55.1 69.7l25.1-19.8 3 3.4c1.3 1.5 3.7.9 4.2-1.1l4-16.3c.4-1.7-1-3.3-2.7-3L72.1 35c-2 .2-2.9 2.7-1.6 4.1l3 3.4-22.4 22.8c-1 1-1.2 2.7-.2 3.9 1 1.3 2.9 1.5 4.2.5z"></path>
      <path d="M109 99H44V51.1c0-1.7-1.4-3-3-3s-3 1.4-3 3v51c0 1.7 1.4 3 3 3h68c1.7 0 3-1.4 3-3 .1-1.7-1.4-3.1-3-3.1z"></path>
    </svg>
  );
}

export default ImproveIcon;