import React from "react";

function IncreaseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="100"
      height="100"      
      enableBackground="new 0 0 150 150"
      version="1.1"
      viewBox="0 0 150 150"
      xmlSpace="preserve"
    >
      <circle cx="75" cy="75" r="75" fill="#FCF112" className="st0"></circle>
      <path d="M106.5 72.5l-23.7 28.8c-1.5 1.8-2.3 4.1-2.3 6.4v6.7c0 1.4-1.2 2.6-2.6 2.6H72c-1.4 0-2.6-1.2-2.6-2.6v-6.8c0-2.3-.8-4.6-2.3-6.4L43.6 72.5c-.6-1-.9-2.2-.9-3.4 0-6.2 8.1-9.4 17.1-11.1-.3.9-.5 1.7-.7 2.6-.5 1.5-.5 3.2-.1 4.8-5.1 1.1-8.4 2.7-9.2 3.8 1.5 2 10.8 5.3 25.3 5.3s23.8-3.4 25.3-5.3c-.9-1.2-4.8-3-11-4.1 1.2-2.1 1.9-4.4 2-6.8 8.6 1.7 16.1 4.9 16.1 10.9-.1 1.1-.4 2.2-1 3.3zM76 59.6c-.7.3-1.4.4-2.1.4-.3 0-.5 0-.8-.1-1.6-.2-3.2-.7-4.7-1.4-.4-.2-.7-.3-1.2-.4-1 0-1.3 1-1.5 1.5-.3.9-.5 1.7-.8 2.6-.4 1.6-.1 2.2 1.3 2.9 1.5.7 3.1 1.1 4.7 1.4l.6.1V69c-.1 1 .7 1.9 1.7 1.9H76c.9.1 1.8-.6 1.9-1.6v-.4V68v-1.6c0-.4 0-.5.5-.6 1.9-.5 3.6-1.6 4.8-3.1 1.6-2 2.3-4.6 1.8-7.1-.5-2.7-2.2-5-4.6-6.3-1.3-.7-2.6-1.3-4-1.8l-.6-.2c-.8-.3-1.6-.7-2.2-1.3-.4 0-.6-.5-.6-1 .1-.5.5-.9 1-1.1.4-.2.8-.2 1.2-.2h.6c1.6 0 3.2.4 4.6 1.1.3.2.7.3 1.1.3.2 0 .4 0 .5-.1.5-.3.8-.7.9-1.3l.6-2.1.2-.9c.3-.9-.1-2-1.1-2.3-1.2-.5-2.4-.9-3.7-1.1-.9-.1-.9-.1-.9-1.1 0-2.6-.3-3.1-2.8-3.1H74c-1.5 0-2 .6-2 2.1v1.5c0 .9 0 .9-.9 1.2-3.8 1.4-5.9 4-6.1 7.7-.2 3.2 1.3 5.7 4.5 7.6 1.2.7 2.4 1.2 3.6 1.7.5.2 1.1.4 1.6.7.6.2 1.2.6 1.7 1 .8.5 1.1 1.5.7 2.3-.2.2-.7.6-1.1.7z"></path>
    </svg>
  );
}

export default IncreaseIcon;