import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import AevMain from "../../components/animated-explainer-video/aevMain"
import AevWorks from "../../components/animated-explainer-video/aevWorks"
import AevShowReel from "../../components/animated-explainer-video/aevShowReel"
import AevWorkCategory from "../../components/animated-explainer-video/aevWorkCategory"
import AevWhyYouNeed from "../../components/animated-explainer-video/aevWhyYouNeed"
import AevTestimonialHighlight from "../../components/animated-explainer-video/aevTestimonialHighlight"

import AevTypes from "../../components/animated-explainer-video/aevTypes"
import AevMoreAbout from "../../components/animated-explainer-video/aevMoreAbout"
import AevCaseStudyHdfc from "../../components/animated-explainer-video/aevCaseStudyHdfc"
import CompanyLogo from "../../components/global/companyLogo"
import AevTestimonials from "../../components/animated-explainer-video/aevTestimonials"
import ProjectInMind from "../../components/global/projectInMind"
import HomeForm from "../../components/home/homeForm"
import DirectContact from "../../components/global/directContact"

import aevFeaturedImage from "../../../static/animated-explainer-video/Featured-Animated-Explainer-Video.png"

import "../../sass/pages/animated-explainer-video.scss"
import "../../sass/global/work-category.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={aevFeaturedImage}
      title="Where We Produce The Best Explainer Videos | WowMakers"
      description="Looking for a top explainer video production company? We create highly converting 2D animations and whiteboard explainer videos that people will love."
    />
  </> 
)

const AnimatedExplainerVideo = () => {
  return (
    <Layout>
      <Seo
        bclass="animated-explainer-video"
        bid="animated-explainer-video"
      ></Seo>
      <ScrollIndicationHeightZero />
      <AevMain />
      <AevWorks />
      <AevShowReel />
      <AevWorkCategory />
      <AevWhyYouNeed />
      <AevTestimonialHighlight />
      <AevTypes />
      <AevMoreAbout />
      <AevCaseStudyHdfc />
      <CompanyLogo />
      <AevTestimonials />
      <ProjectInMind title="Do you have a story to tell the world?" />
      <HomeForm page="explainer-video"/>
      <DirectContact />
    </Layout>
  )
}

export default AnimatedExplainerVideo
