import React from "react";

function ConveyMessageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="100"
      height="100"
      enableBackground="new 0 0 150 150"
      version="1.1"
      viewBox="0 0 150 150"
      xmlSpace="preserve"
    >
      <circle cx="75" cy="75" r="75" fill="#FCF112" className="st0"></circle>
      <path d="M50.6 95.1c.7-.3 3.1-1.1 3.1-1.1 2.8 3.7 5.7 1.5 6.7 3.9 1.2 2.8 3.9 9 4.8 11.1.9 2.1 3 4 4.5 3.5 1.5-.6 6.6-2.5 8.6-3.3 2-.7 2.4-2.5 1.8-3.9-.6-1.5-3.3-1.9-4-3.7-.8-1.7-3.2-7.3-3.9-9-1-2.4 1.1-4.3 4.1-4.6 20.4-2.1 24.2 10.5 31.2 7.7 5.4-2.2 4.3-17.8-2.3-33.2-3.6-8.4-8.4-15.6-12.9-20.2-3.8-3.8-7.3-5.7-9.8-4.7-9.1 3.7 5.4 21.6-39.5 39.8-3.9 1.6-4.9 7.9-3.2 11.7.5 1.2 1.3 2.3 2.4 3.3 2.4 2.3 5.7 3.8 8.4 2.7zm37.9-25.4c-5.1-12-4.5-22.9-3.3-23.4 1.2-.5 9 7.2 14.2 19.1 5.1 12 4.7 21.6 3.5 22.1-1.2.6-9.3-5.8-14.4-17.8z"></path>
      <path d="M92 68.2c1.6 3.8 3.9 6.4 5.2 5.9 1.3-.5 1-4-.5-7.8-1.6-3.8-3.9-6.4-5.2-5.9-1.3.6-1.1 4.1.5 7.8z"></path>
    </svg>
  );
}

export default ConveyMessageIcon;