import React from "react"
import ButtonIcon from "../../icon/buttonIcon"
import { Col, Container, Row } from "react-bootstrap"

const ExplainerVideo2d = () => {
  return (
    <div className="video-type wrapper-main explainer-video-2d">
      <Container>
        <Row className="align-items-center">
          <Col md={6} lg={5}>
            <div className="type-content">
              <h3>2D Animated Explainer Videos</h3>
              <p>
                Here we use 2D cartoon style animations to explain how your
                product or service can help your customers.
              </p>
              <p>
                These videos are fun and engaging as we tell the story through
                beautiful characters, thereby humanizing your brand and building
                an emotional connection with your target audience.
              </p>
              <p class="last-child">
                These videos works best for explaining technology products. 2D
                Animation videos are mainly used for marketing and brand
                building purpose on webistes, social media and online promotion.
              </p>
              <a
                href="/animated-explainer-video/#CQL"
                class="btn wowaction-btn drift-chat-popup"
              >
                <ButtonIcon />
                <span>Have a project in mind? Chat with us</span>
              </a>
            </div>
          </Col>
          <Col md={5} lg={6} className="offset-lg-1 offset-md-1">
            <div class="type-video two-d">
              <iframe
                width="100%"
                height="350"
                src="https://www.youtube.com/embed/77JPBRFHRqA"
                frameborder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="YouTube video player"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ExplainerVideo2d
