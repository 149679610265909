import React from "react";

function SocialShareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="100"
      height="100"       
      enableBackground="new 0 0 150 150"
      version="1.1"
      viewBox="0 0 150 150"
      xmlSpace="preserve"
    >
      <circle cx="75" cy="75" r="75" fill="#FCF112" className="st0"></circle>
      <path d="M98.3 79.3c-1.8 0-3.3 1.5-3.3 3.3v14.2c0 2.2-1.7 3.9-3.9 3.9H53.4c-2.2 0-3.9-1.7-3.9-3.9V59.2c0-2.2 1.7-3.9 3.9-3.9h20.4c1.8 0 3.3-1.5 3.3-3.3 0-1.8-1.5-3.3-3.3-3.3H53.4c-5.8 0-10.6 4.8-10.6 10.6v37.6c0 5.8 4.8 10.6 10.6 10.6H91c5.8 0 10.6-4.8 10.6-10.6V82.6c0-1.8-1.4-3.3-3.3-3.3z"></path>
      <path d="M111.2 57.3L91.3 45.9c-1.1-.7-2.5.2-2.5 1.4v9.3C74.1 59.9 63 73.1 63 88.7c0 1.8 1.5 3.3 3.3 3.3 1.8 0 3.3-1.5 3.3-3.3 0-12 8.1-22 19.1-25.2v8.7c0 1.2 1.4 2.1 2.5 1.4l19.9-11.4c2-1 2-3.8.1-4.9z"></path>
    </svg>
  );
}

export default SocialShareIcon;