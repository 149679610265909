import React from "react"
import { Link } from "gatsby"
import ButtonIcon from "../icon/buttonIcon"
import { Container, Row, Col } from "react-bootstrap"

const AevCaseStudyHdfc = () => {
  return (
    <section className="pl-case-study hdfc">
      <Container>
        <Row>
          <Col md={6}>
            <div className="pl-cs-title">
              <h2>
                How Our Explainer Video Helped India’s Largest Private Bank To
                Promote Its App?
              </h2>
              <Link
                to="/case=studies/hdfc/"
                class="btn wowaction-btn drift-chat-popup"
              >
                <ButtonIcon />
                <span>Read Case Study</span>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AevCaseStudyHdfc
