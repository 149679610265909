import React from "react"
import CorporateVideos from "./types/corporateVideos"
import ExplainerVideo2d from "./types/explainerVideo2d"
import WhiteboardAnimationVideos from "./types/whiteboardAnimationVideos"
import { Col, Container, Row } from "react-bootstrap"

const AevTypes = () => {
  return (
    <section className="aev-types">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">
              What type of explainer video you need?
            </h2>
            <p className="sub-desc">
              A story could be told in multiple ways so does an explainer video.
              Based on the complexity of the subject, messaging style, audience
              persona, brand identity and budget, we offer 3 broad categories of
              explainer videos.
            </p>
          </Col>
        </Row>
      </Container>
      <ExplainerVideo2d />
      <WhiteboardAnimationVideos />
      <CorporateVideos />
    </section>
  )
}

export default AevTypes
