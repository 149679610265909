import React from "react"
import GoogleIcon from '../icon/googleIcon'
import { Col, Container, Row } from "react-bootstrap"
import '../../sass/global/testimonial-highlights.scss'

const AevTestimonialHighlight = () => {
  return (
    <section className="testimonial-highlight rajan-anandan py-5">
      <Container>
        <Row>
          <Col lg={5} md={7}>
            <div className="t-content">
              <h2>
                “Needing animated videos? Finally a great, super simple approach
                from WowMakers!”
              </h2>
              <p className="name">RAJAN ANANDAN</p>
              <p className="designation">Vice President India and SEA</p>
              <p className="c-logo">
                  <GoogleIcon />
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AevTestimonialHighlight
