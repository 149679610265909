import React from "react"
import ButtonIcon from "../../icon/buttonIcon"
import { Col, Container, Row } from "react-bootstrap"

const CorporateVideos = () => {
  return (
    <div className="video-type corporate-videos">
      <Container>
        <Row className="align-items-center">
          <Col md={6} lg={5}>
            <div className="type-content">
              <h3>Corporate, Documentary & Promotional Videos</h3>
              <p>
                These are videos that involve real actors, locations and
                properties. They add a human touch to your brand and if you
                prefer real people and background over an animator video, real
                life videos are the way to go.
              </p>
              <p>
                We mainly focus on producing corporate videos, advertisement
                (TVC & online), wildlife documentary videos, customer
                testimonial videos, product videos, stop motion videos and speed
                painting videos.
              </p>
              <p class="last-child">
                This is the recommended style for enterprises and comapnies that
                offer offline products and services.
              </p>
              <a
                href="/animated-explainer-video/#CQL"
                class="btn wowaction-btn drift-chat-popup"
              >
                <ButtonIcon />
                <span>Have a project in mind? Chat with us</span>
              </a>
            </div>
          </Col>
          <Col md={5} lg={6} className="offset-lg-1 offset-md-1">
            <div class="type-video two-d">
              <iframe
                width="100%"
                height="350"
                src="https://www.youtube.com/embed/W6EuQfh-5zY"
                frameborder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="YouTube video player 1"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CorporateVideos
